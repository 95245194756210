import { Route, Routes } from "react-router-dom";
import Layout from "layouts/layout";
import Missing from "layouts/404";
import RequiredAuth from "middlewares/hooks/requiredLogin";
import PersitLogin from "middlewares/global/persistentLogin";
import Login from "pages/authentication/login";
import Register from "pages/authentication/signup";
import Dashboard from "pages/dashboard/dashboard";
import AuthLayOut from "layouts/auth-layout";
import SendSMS from "pages/sms/sms";
import GroupSMS from "pages/sms/sms-group";
import Billing from "pages/billing/billling";
import Contacts from "pages/contacts/contacts";
import Outbox from "pages/outbox/sms-outbox";
import Profile from "pages/profile/profile";
import Verify from "pages/authentication/verfy";
import HomePage from "pages/home/home";
import ForgotPassword from "pages/authentication/forgot";
import ContactUs from "pages/contactus/contactus";
import Developer from "pages/contactus/developer";
function App() {
  return (
    <>
      <Routes>
        <Route path="" element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/developer" element={<Developer />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Register />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route element={<PersitLogin />}>
            <Route element={<RequiredAuth />}>
              <Route element={<AuthLayOut />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="sendsms" element={<SendSMS />} />
                <Route path="groupsms" element={<GroupSMS />} />
                <Route path="buysms" element={<Billing />} />
                <Route path="contacts" element={<Contacts />} />
                <Route path="outbox" element={<Outbox />} />
                <Route path="profile" element={<Profile />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
