import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "assets/logo.png";
import axios from "config/axios";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import useAuth from "middlewares/hooks/useAuth";
import { useState } from "react";

const Login = () => {

    const [remeber, setRemember] = useState(false);
    const onRemember = () => {
      remeber === true ? setRemember(false) : setRemember(true);
    };
    const navigate = useNavigate();
    const location = useLocation();
    const { setAuth } = useAuth();
    const [disabled, setDisabled] = useState(false);
    const from = location.state?.from?.pathname || "/dashboard";
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
  const onLoginAction = async (data) => {
    try {
      setDisabled(true);
      const response = await axios.post("/auth/sessions", data);
      const accesstoken = response?.data?.data?.access_token;
      const refreshtoken = response?.data?.data?.refresh_token;
      const sessionid = response?.data?.data?.session_id;
      setAuth({ sessionid, accesstoken, refreshtoken });
      const verify = {
        refresh: refreshtoken,
        id: sessionid,
        access: accesstoken,
      };
      localStorage["verify"] = JSON.stringify(verify);
      navigate(from, { replace: true });
    } catch (error) {
      if (!error?.response) {
        setDisabled(false);
        toast.error("No server response");
      } else {
        setDisabled(false);
        toast.error(error?.response?.data?.messages);
      }
    }
  };
  return (
    <>
      <Toaster />
      <div className="app app-login p-0">
        <div className="row g-0 app-auth-wrapper">
          <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center p-4">
            <div className="d-flex flex-column align-content-end">
              <div className="app-auth-body mx-auto">
                <div className="app-auth-branding mb-5">
                  <Link className="app-logo" to="/">
                    <img
                      className="me-2"
                      src={Logo}
                      alt="logo"
                      height={"100px"}
                    />
                  </Link>
                </div>

                <div className="card shadow-lg border-0 p-2 auth-form-container text-start">
                  <h4 className="card-header border-0 auth-heading text-center bg-white">
                    Log in to SMS Portal
                  </h4>
                  <form
                    className="card-body auth-form login-form"
                    autoComplete="off"
                    onSubmit={handleSubmit(onLoginAction)}
                  >
                    <div className="email mb-3">
                      <label className="sr-only" htmlFor="username">
                        Phone Number
                      </label>
                      <input
                        id="username"
                        name="username"
                        type="tel"
                        className="form-control signin-email"
                        placeholder="Phone Number"
                        autoComplete="current_phone"
                        style={{ fontSize: "12px" }}
                        autoFocus={true}
                        {...register("username", {
                          required: true,
                        })}
                      />
                      {errors?.username?.type === "required" && (
                        <p className="text-small m-0 text-danger px-2 help-block">
                          Please provide a valid phone number e.g. 0772******
                        </p>
                      )}
                    </div>
                    <div className="password mb-3">
                      <label className="sr-only" htmlFor="password">
                        Password
                      </label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        className="form-control signin-password"
                        placeholder="Password"
                        style={{ fontSize: "12px" }}
                        autoFocus={true}
                        autoComplete="current-password"
                        {...register("password", { required: true })}
                      />
                      {errors?.password?.type === "required" && (
                        <p className="text-small m-0 text-danger px-2 help-block">
                          Please provide a password
                        </p>
                      )}
                      <div className="extra mt-3 row justify-content-between">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={onRemember}
                              id="RememberPassword"
                              defaultChecked={remeber}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="RememberPassword"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="forgot-password text-end">
                            <Link to="/forgot-password">Forgot password?</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        disabled={disabled}
                        type="submit"
                        className="btn app-btn-primary w-100 theme-btn mx-auto"
                      >
                        {!disabled && "Log In"}{" "}
                        {disabled && "Please Wait ....."}
                      </button>
                    </div>
                  </form>

                  <div className="auth-option text-center pt-5">
                    No Account? Sign up{" "}
                    <Link className="text-link" to="/signup">
                      here
                    </Link>
                    .
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
            <div className="auth-background-holder"></div>
            <div className="auth-background-mask"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
