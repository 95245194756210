const HowDoesITWork = () => {
	return (
		<>
			<section className="how-section py-5">
				<div className="container">
					<h3 className="mb-3 text-center font-weight-bold section-title">How Does It Work</h3>
					<div className="mb-5 text-center section-intro">You're only a few simple steps away</div>

					<div className="row">
						<div className="item col-12 col-md-4">
							{/* <div className="icon-holder">
					    <img src="assets/images/streamline-free/monitor-loading-progress.svg" alt="" />
					    <div className="arrow-holder d-none d-lg-inline-block"></div>
				    </div> */}
							<div className="desc p-3">
								<h5><span className="step-count me-2">1</span>Create Account</h5>
								<p>Create a free account using google and receive free sms bundles.</p>
							</div>
						</div>
						<div className="item col-12 col-md-4">
							{/* <div className="icon-holder">
					    <img src="assets/images/streamline-free/monitor-window.svg" alt="" />
					    <div className="arrow-holder d-none d-lg-inline-block"></div>
				    </div> */}
							<div className="desc p-3">
								<h5><span className="step-count me-2">2</span>Verify Your Account</h5>
								<p>Verify your account to allow you access multiple features.</p>
							</div>
						</div>
						<div className="item col-12 col-md-4">
							{/* <div className="icon-holder">
					    <img src="assets/images/streamline-free/monitor-cash-credit-card.svg" alt="" />
				    </div> */}
							<div className="desc p-3">
								<h5><span className="step-count me-2">3</span>Send SMS</h5>
								<p>Send Bulk SMS or intergate into your own platform.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default HowDoesITWork;