import { useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
const Billing = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSave = async (data) => {
    try {
      // console.log(data);
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/billing/payments/mobilemoney",
        data,
        {
          signal: controller.signal,
        }
      );
      // console.log(response?.data)
      toast.success(response?.data?.messages);
      setDisabled(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        setDisabled(false);
      } else {
        console.log(error?.response);
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const controller = new AbortController();
  const getData = async () => {
    try {
      const response = await axiosPrivate.get("/billing/general", {
        signal: controller.signal,
      });
      setData(response.data.data.transactions);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 const columns = useMemo(
   () => [
     {
       id: "contacts",
       header: "Contacts",
       size: 50,
       accessorFn: (row) => row.contact,
     },
     {
       id: "type", //normal accessorKey
       header: "Route",
       size: 50,
       accessorFn: (row) => row.type,
     },
     {
       id: "status",
       header: "Status",
       size: 50,
       accessorFn: (row) =>
         row.status === "pending" ? (
           <span className="badge bg-info text-sm" style={{ fontSize: "11px" }}>
             Pending
           </span>
         ) : row.status === "success" ? (
           <span
             className="badge bg-success text-sm"
             style={{ fontSize: "11px" }}
           >
             SuccessFul
           </span>
         ) : (
           <span className="badge bg- text-sm" style={{ fontSize: "11px" }}>
             Failed
           </span>
         ),
     },
     {
       accessorKey: "amount",
       header: "Amount",
       size: 50,
     },
     {
       accessorKey: "timestamp",
       header: "Date",
       size: 150,
     },
   ],
   []
 );

  return (
    <>
      <Toaster />
      <div className="app-wrapper">
        <div className="app-content pt-3 p-md-3 p-lg-4">
          <div className="container-xl">
            <div className="card">
              <div className="card-header bg-white">
                <h1 className="app-page-title">Purchase / History</h1>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <form
                      noValidate
                      className="card"
                      onSubmit={handleSubmit(onSave)}
                    >
                      <div className="card-header bg-white">
                        <p className="app-page-title">Buy SMS</p>
                      </div>
                      <div className="card-body">
                        <div className="form-group col-md-12">
                          <label htmlFor="contact" className="form-label p-2">
                            Phone Number (MTN / AIRTEL)
                          </label>
                          <input
                            type="tel"
                            name="contact"
                            placeholder="Format2567..... or 07...."
                            className="form-control py-3 text-small"
                            {...register("contact", {
                              required: true,
                            })}
                          />
                          {errors?.contact?.type === "required" && (
                            <p className="text-small m-0 px-1 text-start text-danger help-block">
                              Please enter a valid contact
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-12 mb-2">
                          <label htmlFor="amount" className="form-label p-2">
                            Amount
                          </label>
                          <input
                            type="number"
                            name="amount"
                            placeholder="Enter prefered amount *"
                            className="form-control py-3 text-small"
                            {...register("amount", {
                              required: true,
                            })}
                          />
                          {errors?.amount?.type === "required" && (
                            <p className="text-small m-0 px-1 text-start text-danger help-block">
                              Please enter amount
                            </p>
                          )}
                        </div>
                      <button
                        disabled={disabled}
                        type="submit"
                        className="btn btn-primary text-white float-end"
                        >
                        {!disabled && "Buy"} {disabled && "Please Wait"}
                      </button>
                        </div>
                    </form>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <MaterialReactTable
                      columns={columns}
                      data={data ?? []}
                      className="table align-items-center p-0"
                      enableColumnFilterModes
                      enableColumnOrdering
                      enableRowSelection
                      enableGrouping
                      enableStickyHeader
                      state={{ isLoading: isLoading }}
                      muiTableContainerProps={{ sx: { maxHeight: "400px" } }}
                      initialState={{ density: "compact" }}
                      muiTableHeadCellProps={{
                        //simple styling with the `sx` prop, works just like a style prop in this example
                        sx: {
                          fontWeight: "normal",
                          fontSize: "12px",
                          fontFamily: "Nunito",
                        },
                      }}
                      muiTableBodyCellProps={{
                        sx: {
                          fontWeight: "normal",
                          fontSize: "12px",
                          fontFamily: "Nunito",
                        },
                      }}
                      muiSearchTextFieldProps={{
                        placeholder: "Search",
                        sx: { fontFamily: "Nunito", fontSize: "12px" },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billing;
