import { useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
const Contacts = () => {
const axiosPrivate = useAxiosPrivate();
const navigate = useNavigate();
const location = useLocation();
const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const controller = new AbortController();
  const getData = async () => {
    try {
      const response = await axiosPrivate.get("/contacts/groups", {
        signal: controller.signal,
      });
      setData(response.data.data.contacts);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
const {
  register,
  handleSubmit,
  reset,
  formState: { errors },
} = useForm();
const onSave = async (data) => {
  try {
    console.log(data);
    setDisabled(true);
    const controller = new AbortController();
    const response = await axiosPrivate.post("/contacts/groups", data, {
      signal: controller.signal,
    });
    // console.log(response?.data)
    toast.success(response?.data?.messages);
    setDisabled(false);
    reset();
    getData();
  } catch (error) {
    if (!error?.response) {
      toast.error("No server response");
      setDisabled(false);
    } else {
      console.log(error?.response);
      setDisabled(false);
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
      toast.error(error?.response?.data?.messages, {
        style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
      });
    }
  }
};
 const columns = useMemo(
   () => [
     {
       id: "title",
       header: "Title",
       size: 50,
       accessorFn: (row) => row.title,
     },

     {
       id: "contacts",
       header: "Contacts",
       size: 50,
       accessorFn: (row) => row.contacts.length,
     },

     {
       accessorKey: "timestamp",
       header: "Date",
       size: 150,
     },
     {
       accessorKey: "action",
       header: "Action",
       size: 150,
     },
   ],
   []
 );
  return (
    <>
      <Toaster />
      <div className="app-wrapper">
        <div className="app-content pt-3 p-md-3 p-lg-4">
          <div className="container-xl">
            <div className="card">
              <div className="card-header bg-white">
                <h1 className="app-page-title">Contact Groups</h1>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <form
                      noValidate
                      className="card"
                      onSubmit={handleSubmit(onSave)}
                    >
                      <div className="card-header bg-white">
                        <p className="app-page-title">Add Group</p>
                      </div>
                      <div className="card-body">
                        <div className="form-group">
                          <label htmlFor="title" className="form-label p-2">
                            Group Title
                          </label>
                          <input
                            type="text"
                            name="title"
                            placeholder="Enter Title"
                            className="form-control py-3"
                            {...register("title", {
                              required: true,
                            })}
                          />
                          {errors?.title?.type === "required" && (
                            <p className="text-small m-0 px-1 text-start text-danger help-block">
                              Please enter a valid group title
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-12 mb-3">
                          <label
                            htmlFor="recipients"
                            className="form-label p-2"
                          >
                            Contacts
                          </label>
                          <textarea
                            type="text"
                            name="recipients"
                            placeholder="Enter Contacts Here *"
                            rows={8}
                            className="form-control"
                            {...register("recipients", {
                              required: true,
                            })}
                          ></textarea>
                          {errors?.recipients?.type === "required" && (
                            <p className="text-small m-0 px-1 text-start text-danger help-block">
                              Please enter some contacts
                            </p>
                          )}
                        </div>
                        <button
                          disabled={disabled}
                          type="submit"
                          className="btn btn-primary text-white float-end"
                        >
                          {!disabled && "Add Contacts"} {disabled && "Please Wait"}
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <MaterialReactTable
                      columns={columns}
                      data={data ?? []}
                      className="table align-items-center p-0"
                      enableColumnFilterModes
                      enableColumnOrdering
                      enableRowSelection
                      enableGrouping
                      enableStickyHeader
                      state={{ isLoading: isLoading }}
                      muiTableContainerProps={{ sx: { maxHeight: "400px" } }}
                      initialState={{ density: "compact" }}
                      muiTableHeadCellProps={{
                        //simple styling with the `sx` prop, works just like a style prop in this example
                        sx: {
                          fontWeight: "normal",
                          fontSize: "12px",
                          fontFamily: "Nunito",
                        },
                      }}
                      muiTableBodyCellProps={{
                        sx: {
                          fontWeight: "normal",
                          fontSize: "12px",
                          fontFamily: "Nunito",
                        },
                      }}
                      muiSearchTextFieldProps={{
                        placeholder: "Search",
                        sx: { fontFamily: "Nunito", fontSize: "12px" },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contacts;
