import { useMemo, useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
const Outbox = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   const controller = new AbortController();
   const getData = async () => {
     try {
       const response = await axiosPrivate.get("/outbox/general", {
         signal: controller.signal,
       });
       setData(response.data.data.sms);
       setIsLoading(false);
     } catch (error) {
       if (error?.response?.status === 401) {
         navigate("/", {
           state: { from: location },
           replace: true,
         });
       }
     }
   };
   useEffect(() => {
     getData();
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
  const columns = useMemo(
    () => [
      {
        id: "contacts",
        header: "N0.contacts",
        size: 50,
        accessorFn: (row) => row.contacts.length,
      },
      {
        id: "type", //normal accessorKey
        header: "Route",
        size: 50,
        accessorFn: (row) =>
          row.type === "info"
            ? "Info SMS"
            : row.type === "number"
            ? "Number Route"
            : "",
      },
      {
        id: "status",
        header: "Status",
        size: 50,
        accessorFn: (row) =>
          row.status === "pending" ? (
            <span
              className="badge bg-info text-sm"
              style={{ fontSize: "11px" }}
            >
              Pending
            </span>
          ) : row.status === "sent" ? (
            <span
              className="badge bg-success text-sm"
              style={{ fontSize: "11px" }}
            >
              Sent
            </span>
          ) : (
            <span className="badge bg- text-sm" style={{ fontSize: "11px" }}>
              Failed
            </span>
          ),
      },
      {
        accessorKey: "cost",
        header: "Cost",
        size: 50,
      },
      {
        accessorKey: "timestamp",
        header: "Sent Time",
        size: 150,
      },
      {
        accessorKey: "route",
        header: "Route",
        size: 50,
      },
      {
        id: "action",
        header: "Action",
        size: 150,
        accessorFn: (row) => (
          <>
            <button
              className="btn text-white btn-primary m-1 text-small"
            >
              Message
            </button>
            
          </>
        ),
      },
    ],
    []
  );
  return (
    <>
      <div className="app-wrapper">
        <div className="app-content pt-3 p-md-3 p-lg-4">
          <div className="container-xl">
            <div className="card">
              <div className="card-header bg-white">
                <h1 className="app-page-title">Outbox</h1>
              </div>
              <div className="card-body">
                <MaterialReactTable
                  columns={columns}
                  data={data ?? []}
                  className="table align-items-center p-0"
                  enableColumnFilterModes
                  enableColumnOrdering
                  enableRowSelection
                  enableGrouping
                  enableStickyHeader
                  state={{ isLoading: isLoading }}
                  muiTableContainerProps={{ sx: { maxHeight: "400px" } }}
                  initialState={{ density: "compact" }}
                  muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                      fontWeight: "normal",
                      fontSize: "12px",
                      fontFamily: "Nunito",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      fontWeight: "normal",
                      fontSize: "12px",
                      fontFamily: "Nunito",
                    },
                  }}
                  muiSearchTextFieldProps={{
                    placeholder: "Search",
                    sx: { fontFamily: "Nunito", fontSize: "12px" },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Outbox;
