import Logo from "assets/logo.png";
import Avatar from "assets/user.png";
import { useEffect, useState } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLogout from "middlewares/hooks/useLogout";

const SideBar = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const controller = new AbortController();
  const [profile, setProfile] = useState([]);

   const getProfile = async () => {
     try {
       const response = await axiosPrivate.get("/accounts/profile", {
         signal: controller.signal,
       });
       setProfile(response.data.data.user);
     } catch (error) {
       // console.log(error)
       if (error?.response?.status === 401) {
         navigate("/login", { state: { from: location }, replace: true });
       }
     }
   };
   useEffect(() => {
     getProfile();
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const logout = useLogout();
   const signOut = async () => {
     if (window.confirm("Are you sure you want to logout?")) {
       logout();
       localStorage.clear();
       navigate("/login");
     }
   };
   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

   const handleSidebarToggle = () => {
     setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
   };
    return (
      <>
        <header className="app-header fixed-top">
          <div className="app-header-inner">
            <div className="container-fluid py-2">
              <div className="app-header-content">
                <div className="row justify-content-between align-items-center">
                  <div className="col-auto">
                    <Link
                      id="sidepanel-toggler"
                      onClick={handleSidebarToggle}
                      className="sidepanel-toggler d-inline-block d-xl-none px-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        role="img"
                      >
                        <title>Menu</title>
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="2"
                          d="M4 7h22M4 15h22M4 23h22"
                        ></path>
                      </svg>
                    </Link>
                    <button className="btn btn-sm text-small btn-info text-white">
                      Balance: (UGX) {profile?.balance?.toLocaleString()}
                    </button>
                  </div>
                  <div className="app-utilities col-auto">
                    <div className="app-utility-item app-user-dropdown dropdown">
                      <Link
                        className="dropdown-toggle"
                        id="user-dropdown-toggle"
                        data-bs-toggle="dropdown"
                        href="#"
                        role="button"
                        aria-expanded="false"
                      >
                        <img src={Avatar} alt="user profile" />
                      </Link>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="user-dropdown-toggle"
                      >
                        <li>
                          <Link className="dropdown-item" to="/profile">
                            Account
                          </Link>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <button onClick={signOut} className="dropdown-item">
                            Log Out
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="app-sidepanel"
            className={`app-sidepanel sidebar ${isSidebarOpen ? "open" : ""}`}
          >
            <div id="sidepanel-drop" className="sidepanel-drop"></div>
            <div className="sidepanel-inner d-flex flex-column">
              <div className="app-branding d-flex justify-content-center">
                <Link className="app-logo" to="/dashboard">
                  <img
                    className="text-center  me-2"
                    src={Logo}
                    alt="logo"
                    height={"50px"}
                  />
                </Link>
                <Link
                  onClick={handleSidebarToggle}
                  className="d-inline-block d-xl-none"
                  style={{ marginLeft: "90px" }}
                >
                  <i className="fa fa-times fa-2x text-dark"></i>
                </Link>
              </div>

              <nav
                id="app-nav-main"
                className="app-nav app-nav-main flex-grow-1"
              >
                <ul
                  className="app-menu list-unstyled accordion"
                  id="menu-accordion"
                >
                  <li className="nav-item">
                    <Link className="nav-link active" to="/dashboard">
                      <span className="nav-icon">
                        <i className="fa fa-home"></i>
                      </span>
                      <span className="nav-link-text">Overview</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/sendsms">
                      <span className="nav-icon">
                        <i className="fa fa-message"></i>
                      </span>
                      <span className="nav-link-text">Send SMS</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/groupsms">
                      <span className="nav-icon">
                        <i className="fa fa-users"></i>
                      </span>
                      <span className="nav-link-text">Group SMS</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/buysms">
                      <span className="nav-icon">
                        <i className="fa fa-dollar"></i>
                      </span>
                      <span className="nav-link-text">Buy SMS</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/outbox">
                      <span className="nav-icon">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <span className="nav-link-text">Outbox</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contacts">
                      <span className="nav-icon">
                        <i className="fa fa-address-book"></i>
                      </span>
                      <span className="nav-link-text">Contacts</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/profile">
                      <span className="nav-icon">
                        <i className="fa fa-gears"></i>
                      </span>
                      <span className="nav-link-text">Profile</span>
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className="app-sidepanel-footer">
                <nav className="app-nav app-nav-footer">
                  <ul className="app-menu footer-menu list-unstyled">
                    <li className="nav-item">
                      <button className="nav-link" onClick={signOut}>
                        <span className="nav-icon">
                          <i className="fa fa-right-from-bracket"></i>
                        </span>
                        <span className="nav-link-text">Logout</span>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </>
    );
}
 
export default SideBar;