import { useEffect, useState } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

   const [contacts, setContacts] = useState([]);
   const [outbox, setOutbox] = useState([]);
   const [profile, setProfile] = useState([]);
   const [recentPayments, setRecentPayments] = useState([]);
   const controller = new AbortController();

   const getContacts = async () => {
     try {
       const response = await axiosPrivate.get("/contacts/groups", {
         signal: controller.signal,
       });
       setContacts(response.data.data.rows_returned);
     } catch (error) {
       // console.log(error)
       if (error?.response?.status === 401) {
         navigate("/login", { state: { from: location }, replace: true });
       }
     }
   };
   const getSmsSent = async () => {
     try {
       const response = await axiosPrivate.get("/outbox/general", {
         signal: controller.signal,
       });
       setOutbox(response.data.data);
     } catch (error) {
       // console.log(error)
       if (error?.response?.status === 401) {
         navigate("/login", { state: { from: location }, replace: true });
       }
     }
   };
   const getProfile = async () => {
     try {
       const response = await axiosPrivate.get("/accounts/profile", {
         signal: controller.signal,
       });
       setProfile(response.data.data.user);
     } catch (error) {
       // console.log(error)
       if (error?.response?.status === 401) {
         navigate("/login", { state: { from: location }, replace: true });
       }
     }
   };

   const getRecentPayments = async () => {
     try {
       const response = await axiosPrivate.get("/billing/general", {
         signal: controller.signal,
       });
       setRecentPayments(response.data.data.transactions);
     } catch (error) {
       // console.log(error)
       if (error?.response?.status === 401) {
         navigate("/login", { state: { from: location }, replace: true });
       }
     }
   };

   useEffect(() => {
     getContacts();
     getSmsSent();
     getProfile();
     getRecentPayments();
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

  return (
    <div className="app-wrapper">
      <div className="app-content pt-3 p-md-3 p-lg-4">
        <div className="container-xl">
          <h1 className="app-page-title">Overview</h1>

          <div
            className="app-card alert alert-dismissible shadow-sm mb-4 border-left-decoration"
            role="alert"
          >
            <div className="inner">
              <div className="app-card-body p-3 p-lg-4">
                <h5 className="mb-3">
                  Welcome, {`${profile?.firstname} ${profile?.lastname} !`}
                </h5>
              </div>
            </div>
          </div>

          <div className="row g-4 mb-4">
            <div className="col-md-3">
              <div className="app-card app-card-stat shadow-sm h-100">
                <div className="app-card-body p-3 p-lg-4">
                  <p className="mb-1 text-bold text-dark">Balance</p>
                  <div className="text-center text-dashboard">
                    UGX {profile?.balance?.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="app-card app-card-stat shadow-sm h-100">
                <div className="app-card-body p-3 p-lg-4">
                  <p className="mb-1">Contact Groups</p>
                  <div className="text-center text-dashboard">{contacts}</div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="app-card app-card-stat shadow-sm h-100">
                <div className="app-card-body p-3 p-lg-4">
                  <p className="mb-1">Bulk SMS</p>
                  <div className="text-center text-dashboard">
                    {
                      outbox?.sms?.filter((sms) => sms.route === "basic")
                        ?.length
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="app-card app-card-stat shadow-sm h-100">
                <div className="app-card-body p-3 p-lg-4">
                  <p className="mb-1">API</p>
                  <div className="text-center text-small">
                    {outbox?.sms?.filter((sms) => sms.route === "api")?.length}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-4 mb-4">
            <div className="card">
              <div className="card-header p-3 bg-white">Recent Payments</div>
              <div className="card-body inner">
                <table className="table table-striped table-bordered p-0 text-dashboard">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Method</th>
                      <th>Contact</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recentPayments?.slice(0, 5)?.map((payment, index) => (
                      <tr key={payment.id}>
                        <td>{payment?.timestamp}</td>
                        <td>{payment?.amount.toLocaleString()}</td>
                        <td>{payment?.type}</td>
                        <td>{payment?.contact}</td>
                        <td>
                          <p className="badge bg-success">
                            {payment?.status}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
