import BannerImage from "assets/images/undraw_message_sent_re_q2kl.svg";
import { Link } from "react-router-dom";
const Banner = () => {
    return ( 
        <>
        <section className="hero-section py-3 py-md-5 " style={{marginTop: "100px"}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 pt-3 mb-5 mb-lg-0">
                        <h1 className="site-headline font-weight-bold mb-3">Sign Up Today.</h1>
                        <div className="site-tagline mb-4">Send Bulk SMS, Transactional Messages At A Cheap Cost.</div>
                        <div className="cta-btns mb-lg-3">
                            <Link className="btn btn-primary text-white me-2 mb-3" to="/login">Login</Link>
                            <Link className="btn btn-secondary mb-3" to="/signup">Sign Up</Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 text-center">
                        <img className="mx-auto" height={"250px"} src={ BannerImage } alt="" />
                    </div>
                </div>
            </div>
        </section>
        </>
     );
}
 
export default Banner;