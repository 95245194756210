import { Link } from "react-router-dom";
import Logo from "assets/logo-white.png";
const Header = () => {
  return (
    <>
      <header className="header fixed-top bg-dark">
        <div className="branding">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-dark">
              <div className="site-logo">
                <Link className="navbar-brand" to="/">
                  <img
                    className="logo-icon me-2"
                    src={Logo}
                    height={50}
                    alt="logo"
                  />
                </Link>
              </div>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navigation"
                aria-controls="navigation"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span> </span>
                <span> </span>
                <span> </span>
              </button>

              <div
                className="collapse navbar-collapse py-3 py-lg-0"
                id="navigation"
              >
                <ul className="navbar-nav ms-lg-auto">
                  <li className="nav-item me-lg-4">
                    <Link className="nav-link text-white" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item me-lg-4">
                    <Link className="nav-link text-white" to="/developer">
                      Developer
                    </Link>
                  </li>
                  {/* <li className="nav-item me-lg-4">
                    <Link className="nav-link" to="/products">
                      Products
                    </Link>
                  </li> */}
                  {/* <li className="nav-item me-lg-4">
                    <Link className="nav-link" to="/services">
                      Services
                    </Link>
                  </li> */}
                  {/* <li className="nav-item me-lg-4">
                    <Link className="nav-link" to="/blog">
                      Blog
                    </Link>
                  </li> */}
                  <li className="nav-item me-lg-4">
                    <Link className="nav-link text-white" to="/contactus">
                      Contact Us
                    </Link>
                  </li>
                  <li className="nav-item me-lg-0 mt-3 mt-lg-0">
                    <Link
                      className="btn btn-sm btn-primary rounded-2 text-white"
                      to="/login"
                    >
                      Login
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
