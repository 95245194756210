import Logo from "assets/logo.png";
import { useState} from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../config/axios";
import toast, { Toaster } from "react-hot-toast";
import useAuth from "middlewares/hooks/useAuth";
const ForgotPassword = () => {
  const [disabled, setDisabled] = useState(false);
  // const nagivate = useNavigate();
  const { auth } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // console.log(data)
    try {
      setDisabled(true);
      await axios.post("/register/verify", data, {
        headers: { Authorization: auth?.token },
      });
      // nagivate("/login");
    } catch (error) {
      if (!error?.response) {
        toast("No server response");
        setDisabled(false);
      } else {
        setDisabled(false);
        // toast(error?.response?.data?.messages);
      }
    }
  };

  return (
    <>
      <Toaster />
      <div className="app app-login p-0">
        <div className="row g-0 app-auth-wrapper">
          <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
            <div className="auth-background-holder"></div>
            <div className="auth-background-mask"></div>
          </div>
          <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center p-4">
            <div className="d-flex flex-column align-content-end">
              <div className="app-auth-body mx-auto">
                <div className="app-auth-branding mb-5">
                  <Link className="app-logo" to="/">
                    <img
                      className="me-2"
                      src={Logo}
                      alt="logo"
                      height={"100px"}
                    />
                  </Link>
                </div>

                <div className="card shadow-lg border-0 p-2 auth-form-container text-start">
                  <h4 className="card-header border-0 auth-heading text-center bg-white">
                    Recover Account
                  </h4>
                  <form
                    className="card-body auth-form login-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="email mb-3">
                      <label className="sr-only" htmlFor="phonenumber">
                        Enter Phone Number
                      </label>
                      <input
                        id="phonenumber"
                        name="phonenumber"
                        type="tel"
                        className="form-control signin-email"
                        placeholder="Phone Number"
                        autoComplete="off"
                        style={{ fontSize: "12px" }}
                        {...register("otp", { required: true })}
                      />
                      {errors?.otp?.type === "required" && (
                        <p className="text-small text-center text-danger help-block">
                          Please enter your phone number
                        </p>
                      )}
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        disabled={disabled}
                        className="btn app-btn-primary w-100 theme-btn mx-auto"
                      >
                        {!disabled && "Recover"}{" "}
                        {disabled && "Please Wait ....."}
                      </button>
                    </div>
                  </form>

                </div>
              </div>

             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
