import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useState, useEffect, useRef } from "react";
import Select from "react-select";
const GroupSMS = () => {
  const [text, setText] = useState("");
  const [messageCount, setMessageCount] = useState(0);
  const charactersPerMessage = 160;
  const textareaRef = useRef(null);
  const textvalueRef = useRef(null);
  const formRef = useRef(null);
  const controller = new AbortController();

  const [contactsError, setContactsError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const handleChange = (event) => {
    const { value } = event.target;
    setText(value);
  };

  useEffect(() => {
    const messages = Math.ceil(text.length / charactersPerMessage);
    setMessageCount(messages);
  }, [text]);

  const remainingCharacters = text.length % charactersPerMessage;
  const [groups, setGroups] = useState([]);

  const getContactGroups = async () => {
    try {
      const response = await axiosPrivate.get("/contacts/groups", {
        signal: controller.signal,
      });
      setGroups(response.data.data.contacts);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getContactGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = groups?.map((group) => ({
    value: `${group.id}`,
    label: `${group.title}`,
  }));
  const [contacts, setContacts] = useState([]);
  const handleChangeOption = (opts) => {
    const selectedValues = opts.map((opt) => opt.value);
    setContacts(selectedValues);
  };

  // console.log(contacts)
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const onSave = async (event) => {
    event.preventDefault();
    if (contacts.length === 0) {
      setContactsError(true);
      textareaRef.current.focus();
      return;
    }
    if (text.length === 0) {
      setMessageError(true);
      textvalueRef.current.focus();
      return;
    }

    try {
      setDisabled(true);
      const newData = {
        message: text,
        recipients: contacts,
        type: "info",
      };
      // console.log(newData);
      const response = await axiosPrivate.post(
        "/sms/send/group/info",
        newData,
        {
          signal: controller.signal,
        }
      );
      console.log(response.data);
      toast.success(response?.data?.messages);

      setDisabled(false);
      formRef.current.reset();
      setText("");
      setContacts([]);
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");

        setDisabled(false);
      } else {
        console.log(error?.response);

        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };
  return (
    <>
      <Toaster />
      <div className="app-wrapper">
        <div className="app-content pt-3 p-md-3 p-lg-4">
          <div className="container-xl">
            <div className="card">
              <div className="card-header bg-white">
                <h1 className="app-page-title">Send Group Bulk SMS</h1>
              </div>
              <div className="card-body">
                <form ref={formRef} onSubmit={onSave}>
                  <div className="row">
                    <div className="col-md-7 col-sm-12">
                      <div className="form-floating mb-3">
                        <Select
                          className="border-1 form-control-md an-simple-select"
                          placeholder="Recipient Contacts Groups * "
                          styles={customStyles}
                          options={options}
                          onChange={handleChangeOption}
                          onFocus={() => {
                            // Change the textarea background color when focused
                            textvalueRef.current.style.backgroundColor =
                              "#fcf8f7";
                          }}
                          onBlur={() => {
                            // Reset the textarea background color when focus is lost
                            textvalueRef.current.style.backgroundColor =
                              "white";
                          }}
                          isMulti={true}
                          ref={textareaRef}
                          isClearable
                        />
                        {/* <label htmlFor="contacts">Recipient Contacts</label> */}
                        {contactsError && contacts.length === 0 && (
                          <p className="text-small m-0 px-1 text-start text-danger help-block">
                            Contacts Groups field can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-12">
                      <p className="text-medium">
                        Select Groups{" "}
                        <span className="text-bold">
                        </span>
                      </p>
                    </div>
                    <div className="col-md-7 col-sm-12">
                      <div className="form-floating mb-3">
                        <textarea
                          type="text"
                          className="form-control"
                          id="message"
                          value={text}
                          onChange={handleChange}
                          style={{
                            height: "200px",
                            border:
                              messageError && text.length === 0
                                ? "2px solid red"
                                : "1px solid #ccc",
                          }}
                          ref={textvalueRef}
                          onFocus={() => {
                            // Change the textarea background color when focused
                            textvalueRef.current.style.backgroundColor =
                              "#fcf8f7";
                          }}
                          onBlur={() => {
                            // Reset the textarea background color when focus is lost
                            textvalueRef.current.style.backgroundColor =
                              "white";
                          }}
                        ></textarea>
                        <label htmlFor="message">Message</label>
                        {messageError && text.length === 0 && (
                          <p className="text-small m-0 px-1 text-start text-danger help-block">
                            Message field can not be empty
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-12">
                      <p className="text-medium">
                        Characters remaining: {remainingCharacters}/
                        {charactersPerMessage}
                      </p>
                      <p>Number of messages: {messageCount}</p>
                    </div>

                    <div className="float-end mt-3 mt-md-0">
                      <button
                        type="submit"
                        className="btn btn-info text-white font-medium rounded-0 px-4"
                        disabled={disabled}
                      >
                        <div className="d-flex align-items-center">
                          <i className="ti ti-send me-2 fs-4"></i>
                          {!disabled && "Send SMS"}{" "}
                          {disabled && "Please Wait ....."}
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupSMS;
