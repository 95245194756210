import { Outlet } from "react-router-dom";
import SideBar from "./sidebar";

const AuthLayOut = ({ children }) => {
  return (
    <>
      <div className="app">
        <SideBar />
        {children}
        <Outlet />
      </div>
    </>
  );
};

export default AuthLayOut;
